<template>
  <div class="row">
    <div class="col-12 g">
        <div class="card card-body">
            <h4 class="g">
                المخلفات السلوكية <button class="btn btn-primary btn-sm bbbtn" v-b-modal.add @click="saved =false;">
                    <i class="fa fa-plus"></i>
                    تسجيل مخالفة جديدة
                </button>
                &nbsp;
                <select name="" v-model="selectedclassroom" id="">
                    <option value="all">استعراض فصل معين</option>
                    <option v-for="c in classrooms" :key="c" :value="c">{{ c }}</option>
                </select>
                &nbsp;
                <div class="form-check">
                  <label class="form-check-label">
                    <input type="checkbox" class="form-check-input" v-model="select_mode" :value="true">
                    طباعة جماعية
                  </label>
                </div>
                <button v-if="select_mode" class="btn btn-success" @click="print(selected_to_print)"><i class="fa fa-print"></i> طباعة المحدد الآن</button>
            </h4>
            <div class="col-12 table-responsive">
                <table class="table table-hover table-bordered table-sm">
                    <thead>
                        <th>
                            الطلاب
                        </th>
                        <th>
                            التاريخ
                        </th>
                        <th>
                            المخالفة
                        </th>
                        <th>
                            الاجراء المتخذ
                        </th>
                        <th>
                            تم الارسال لولي الامر
                        </th>
                        <th>
                            خيارات
                        </th>
                    </thead>
                    <tbody>
                        <template  v-for="bi in bis">
                        <tr v-if="selectedclassroomvalid(bi)" :key="bi.name">
                            <td>
                                
                                <span v-for="(s, i) in bi.students.map(function(x){
                                    return x
                                })" :key="i">
                                <span v-if="!select_mode">
                                    {{ bi.students.length > 1 ? (i + 1) + '- ' : '' }} {{ s.name }} {{ bi.students.length > 0 ? '(' + s.classname + '-' + s.classroom + ')' : '' }} <button class="btn btn-sm text-primary fa fa-print btn-sm" title="طباعة تعهد" @click="print([{bi: bi, index: i}])"> تعهد</button> <br>
                                </span>

                                <div class="form-check" v-if="select_mode">
                                  <label class="form-check-label">
                                    <input type="checkbox" class="form-check-input" v-model="selected_to_print" :value="{bi: bi, index: i}">
                                    {{ bi.students.length > 1 ? (i + 1) + '- ' : '' }} {{ s.name }} {{ bi.students.length > 0 ? '(' + s.classname + '-' + s.classroom + ')' : '' }} 
                                  </label>
                                </div>
                                </span>
                            </td>
                            <td>
                                {{ bi.date.split(" ")[0] }}
                            </td>
                            <td>
                                {{ bi.bi }}
                            </td>
                            <td v-html="bi.actions.join('<br>')"></td>
                            <td>
                                <span v-if="bi.send.send != '2'" class="btn btn-sm btn-success">تم الارسال عبر {{ bi.send.method }}</span>
                                <span v-if="bi.send.send == '2'" class="btn btn-sm btn-primary">حفظ في الملف فقط</span>
                            </td>
                            <td>
                                <button class="btn btn-sm btn-danger" style="border-radius: 0px" @click="current = bi; createforward.details = bi.bi; createforward.opinion = bi.actions.join('\n'); createforward = JSON.parse(JSON.stringify(createforward))"  v-b-modal.forward>
                                    <i class="fa fa-reply"></i>
                                    تحويل للوكيل
                                </button>
                                <button class="btn btn-sm btn-outline-danger fa fa-trash" style="border-radius: 0px" title="حذف" @click="remove(bi._id)"></button>
                            </td>
                        </tr>
                        </template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <b-modal id="forward" title="تحويل المخالفة للوكيل" hide-footer size="lg">
        <div class="form-group">
            <span for="">نوع المشكلة &nbsp;</span>
            <span id="mm">
                <input type="text" v-model="createforward.type" v-if="ntype" placeholder="اكتب هنا...">
                <select class="form-controla bg-white" v-if="!ntype" @change="ccc(); createforward = JSON.parse(JSON.stringify(createforward))" v-model="createforward.type" required="">
                    <option value="تعليمية">تعليمية</option>
                    <option value="نفسية">نفسية</option>
                    <option value="اجتماعية">اجتماعية</option>
                    <option value="سلوكية">سلوكية</option>
                    <option value="">أخرى.. اكتب</option>
                </select>
            </span>
        </div>
        <div class="form-group">
        <span for="">تكرار المشكلة &nbsp;</span>
            <select class="f bg-white" v-model="createforward.repeat" id="">
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
            </select>
        </div>
        <div class="form-group">
            <p for="">وصف المشكلة كاملة (اختياري)</p>
            <textarea class="form-control" v-model="createforward.details" id="" placeholder="اكتب هنا... (اختياري)" rows="3"></textarea>
        </div>
        <div class="form-group">
            <p for="">الاجراء المتخذ (اختياري)</p>
            <textarea class="form-control" v-model="createforward.opinion" id="" placeholder="اكتب هنا... (اختياري)" rows="3"></textarea>
        </div>
        <div class="form-check">
          <label class="form-check-label">
            <input type="checkbox" class="form-check-input" v-model="createforward.admin" :value="'yes'">
            تحويل للموجه مباشررة
          </label>
        </div>
        <div class="col-12 text-center">
            <button class="btn btn-primary" @click="forward()">
                تحويل {{ createforward.admin ? 'للموجه' : 'للوكيل' }} الآن
            </button>
        </div>
    </b-modal>
    <b-modal id="add" title="تسجيل مخالفة" hide-footer size="lg">
        <div class="g">
            اختر الطلاب <input type="text" placeholder="ابحث هنا..." v-model="q" @change="getStudents()" style="border: none; border: 1px solid #999; padding: 5px"> <button class="btn btn-sm"><i class="fa fa-search"></i> بحث</button>
        </div>
        <div style="width: 100%; border: solid 2px #333; padding: 5px; overflow-y: scroll; height: 150px;">
            <div class="form-check" v-for="student in students" :key="student._id">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" v-model="selected" :value="student.number">
                {{ student.name }}
              </label>
            </div>
        </div>
       <p class="g">تم تحديد {{ selected.length }} طالب</p>
       <div class="form-group">
         <label for="">المرحلة</label>
         <select class="form-control" v-model="stage">
           <option value="1">المرحلة الابتدائية</option>
           <option value="2">المرحلتين المتوسطة والثانوية</option>
         </select>
       </div>
       <div class="form-group">
         <label for="">درجة المخالفة</label>
         <select class="form-control" v-model="level">
           <option value="1">الاولى</option>
           <option value="2">الثانية</option>
           <option value="3">الثالثة</option>
           <option value="4">الرابعة</option>
           <option value="5">الخامسة</option>
         </select>
       </div>
       <div class="form-group" v-if="manual_bi">
         <label for="">المخالفة <u><a href="javascript:;" @click="manual_bi = !manual_bi">اختر من القائمة</a></u></label>
         <input type="text"
           class="form-control" v-model="bi" placeholder="اكتب المخالفة هنا...">
       </div>
       <div class="form-group" v-if="!manual_bi">
         <label for="">المخالفة <u><a href="javascript:;" @click="manual_bi = !manual_bi">اكتب يدويا</a></u></label>
         <select class="form-control" v-if="level == '1' && stage == '1'" v-model="bi">
            <option value="عــدم التقيــد باللبــاس الرســمي الخــاص با لمد ر ســة .">عــدم التقيــد باللبــاس الرســمي الخــاص با لمد ر ســة .</option>
            <option value="النــوم داخــل الفصــل بصفــة متكــررة.">النــوم داخــل الفصــل بصفــة متكــررة.</option>
            <option value="الدخـول أو الخـروج مـن الفصـل دون اسـتئذان.">الدخـول أو الخـروج مـن الفصـل دون اسـتئذان.</option>
            <option value="تكــرار خــروج الطــلاب والطالبــات ودخولهــم مــن البوابــة قبــل الانصــراف والحضــور، والتجمهــر أمــام بوابــة المدرســة">تكــرار خــروج الطــلاب والطالبــات ودخولهــم مــن البوابــة قبــل الانصــراف والحضــور، والتجمهــر أمــام بوابــة المدرســة</option>
         </select>
         <select class="form-control" v-if="level == '1' && stage == '2'" v-model="bi">
            <option value="عــدم التقيــد باللبــاس الرســمي الخــاص بالمدرســة.">عــدم التقيــد باللبــاس الرســمي الخــاص بالمدرســة.</option>
            <option value="العبـث أثنـاء الاصطفاف  الصباحـي أو ضعــف المشــاركة في برامــج الاصطفاف الصباحــي">العبـث أثنـاء الاصطفاف  الصباحـي أو ضعــف المشــاركة في برامــج الاصطفاف الصباحــي</option>
            <option value="إعاقــة ســير الحصــص الدراســية مثــل: الحديــث الجانبــي والنــوم داخــل الفصل..">إعاقــة ســير الحصــص الدراســية مثــل: الحديــث الجانبــي والنــوم داخــل الفصل..</option>
            <option value="تكــرار خــروج ودخــول الطــالب أو الطالبة مــن البوابــة قبــل وقــت الحضــور والانصراف والتجمهــر أمــام بوابــة المدرســة.">تكــرار خــروج ودخــول الطــالب أو الطالبة مــن البوابــة قبــل وقــت الحضــور والانصراف والتجمهــر أمــام بوابــة المدرســة.</option>
         </select>
         <select class="form-control" v-if="level == '2' && stage == '1'" v-model="bi">
            <option value="إثــارة الفوضــى داخــل الفصــل أو داخــل المدرســة أو في وســائل النقــل المدرســي، كالعبــث بالميــاه، والكتابــة علــى الجــدران. . الشــجار أو الاشــتراك في مضاربــة جماعيــة">إثــارة الفوضــى داخــل الفصــل أو داخــل المدرســة أو في وســائل النقــل المدرســي، كالعبــث بالميــاه، والكتابــة علــى الجــدران. . الشــجار أو الاشــتراك في مضاربــة جماعيــة</option>
            <option value="إلحــاق الضــرر المتعمــد بممتلــكات الزمــلاء.">إلحــاق الضــرر المتعمــد بممتلــكات الزمــلاء.</option>
            <option value="التلفــظ علــي الزمـلاء بكلمــات نابيــة أو التهديــد، وإثــارة الرعــب.">التلفــظ علــي الزمـلاء بكلمــات نابيــة أو التهديــد، وإثــارة الرعــب.</option>
            <option value="عــدم حضــور الحصــة الدراســية.">عــدم حضــور الحصــة الدراســية.</option>
            <option value="الهــروب مــن الحصــة الدراســية.">الهــروب مــن الحصــة الدراســية.</option>
            <option value="العبــث بتجهيــزات المدرســة أو مبانيهــا كأجهــزة الحاســوب، أدوات ومعــدات الأمــن والســلامة المدرســية ، الكهربــاء ، المعامــل ، حافلــة المدرســة .">العبــث بتجهيــزات المدرســة أو مبانيهــا كأجهــزة الحاســوب، أدوات ومعــدات الأمــن والســلامة المدرســية ، الكهربــاء ، المعامــل ، حافلــة المدرســة .</option>
            <option value="ســرقة شــيء مــن ممتلــكات الزملاء">ســرقة شــيء مــن ممتلــكات الزملاء</option>
         </select>
         <select class="form-control" v-if="level == '2' && stage == '2'" v-model="bi">
            <option value=" إثــارة الفوضــى داخــل الفصــل أو داخــل المدرســة، في  وســائل النقــل المدرســي مثــل: العبــث بالمــاء، البخاخــات، والصــوت العالــي، الكتابــة علــى الجــدران."> إثــارة الفوضــى داخــل الفصــل أو داخــل المدرســة، في  وســائل النقــل المدرســي مثــل: العبــث بالمــاء، البخاخــات، والصــوت العالــي، الكتابــة علــى الجــدران.</option>
            <option value="الخــروج مــن الفصــل دون اســتئذان، أو عــدم حضــور الحصــة الدراســية ">الخــروج مــن الفصــل دون اســتئذان، أو عــدم حضــور الحصــة الدراســية </option>
         </select>
         <select class="form-control" v-if="level == '3' && stage == '1'" v-model="bi">
            <option value="التلفــظ بكلمــات نابيــة تجــاه المعلميــن/ات أو الادارييــن/ات.">التلفــظ بكلمــات نابيــة تجــاه المعلميــن/ات أو الادارييــن/ات.</option>
            <option value="إلحـاق الضـرر المتعمـد بممتلـكات المعلميـن، أو المدرسـة.">إلحـاق الضـرر المتعمـد بممتلـكات المعلميـن، أو المدرسـة.</option>
            <option value="ســرقة شــيء مــن ممتلــكات المعلميــن أو المدرســة.">ســرقة شــيء مــن ممتلــكات المعلميــن أو المدرســة.</option>
            <option value="تعمــد إتـلاف شــيء مــن تجهيــزات المدرســة أو مبانيهــا كأجهــزة الحاســوب ، أدوات ومعــدات الامــن والسلامة المدرســية، الكهربــاء، المعامــل، الحافلــة المدرســة.">تعمــد إتـلاف شــيء مــن تجهيــزات المدرســة أو مبانيهــا كأجهــزة الحاســوب ، أدوات ومعــدات الامــن والسلامة المدرســية، الكهربــاء، المعامــل، الحافلــة المدرســة.</option>
            <option value="إحضــار أو اســتخدام المــواد أو الالعـاب الخطـرة إلـى المدرسـة، مثـل الالعــاب الناريــة، البخاخــات الغازيــة الملونــة، المــواد الكيمائيــة.">إحضــار أو اســتخدام المــواد أو الالعـاب الخطـرة إلـى المدرسـة، مثـل الالعــاب الناريــة، البخاخــات الغازيــة الملونــة، المــواد الكيمائيــة.</option>
            <option value="حيــازة أو عــرض المــواد الاعلاميــة الممنوعــة المقــروءة، أو المســموعة ، أو المرئيــة .">حيــازة أو عــرض المــواد الاعلاميــة الممنوعــة المقــروءة، أو المســموعة ، أو المرئيــة .</option>
            <option value="إحضـار أجهـزة الاتصـال الشـخصية ( تحتــوي علــى صــور ومقاطــع غيــر لائقة )">إحضـار أجهـزة الاتصـال الشـخصية ( تحتــوي علــى صــور ومقاطــع غيــر لائقة )</option>
            <option value="التوقيــع عــن ولــي الامــر مــن غيــر علمـه علـى المكاتبـات المتبادلـة بيـن المدرســة وولــي الامــر.">التوقيــع عــن ولــي الامــر مــن غيــر علمـه علـى المكاتبـات المتبادلـة بيـن المدرســة وولــي الامــر.</option>
            <option value="مهاجمــة أحــد الزمــلاء  بالضــرب.">مهاجمــة أحــد الزمــلاء  بالضــرب.</option>
            <option value="التنمر">التنمر</option>
            <option value="الهروب من المدرسة.">الهروب من المدرسة.</option>
            <option value="التحـرش الجنسـي غيـر المباشـر">التحـرش الجنسـي غيـر المباشـر</option>
            <option value="تصويــر الطــلاب والطالبــات والمعلميــن بالأجهزة الإلكترونية أيا كان نوعهــا.">تصويــر الطــلاب والطالبــات والمعلميــن بالأجهزة الإلكترونية أيا كان نوعهــا.</option>
            <option value="امتهــان الكتــب الدراســية .">امتهــان الكتــب الدراســية .</option>
         </select>
         <select class="form-control" v-if="level == '3' && stage == '2'" v-model="bi">
            <option value="التهـاون في أداء الصـلاة داخـل المدرسـة .">التهـاون في أداء الصـلاة داخـل المدرسـة .</option>
            <option value=" الإشارة بحــركات مخلــة بالأدب تجــاه الزمــلاء ."> الإشارة بحــركات مخلــة بالأدب تجــاه الزمــلاء .</option>
            <option value="الشــجار أو الاشــتراك في مضاربــة أو مهاجمــة الزملاء وتهديدهــم والتلفــظ عليهــم بألفــاظ غيــر لائقة .">الشــجار أو الاشــتراك في مضاربــة أو مهاجمــة الزملاء وتهديدهــم والتلفــظ عليهــم بألفــاظ غيــر لائقة .</option>
            <option value="إلحــاق الضــرر المتعمــد بممتلــكات الزمــلاء، أو ســرقة شــيء منهــا أو إثــارة الرعــب بينهــم وتخويفهــم.">إلحــاق الضــرر المتعمــد بممتلــكات الزمــلاء، أو ســرقة شــيء منهــا أو إثــارة الرعــب بينهــم وتخويفهــم.</option>
            <option value=" حيــازة المــواد الإعلامية الممنوعــة المقــروءة، المسـموعة، المرئيـة، أو إحضـارمجســمات تعــد ممنوعــة أخلاقيا"> حيــازة المــواد الإعلامية الممنوعــة المقــروءة، المسـموعة، المرئيـة، أو إحضـارمجســمات تعــد ممنوعــة أخلاقيا</option>
            <option value=" إحضــار المــواد أو الألعاب الخطــرة إلــى المدرســة دون اســتخدامها. وذلــك مثــل :الألعاب الناريــة ، والبخاخــات الغازيــة الملونــة."> إحضــار المــواد أو الألعاب الخطــرة إلــى المدرســة دون اســتخدامها. وذلــك مثــل :الألعاب الناريــة ، والبخاخــات الغازيــة الملونــة.</option>
            <option value="حيــازة الســجائر، والســجائر الإلكترونية.">حيــازة الســجائر، والســجائر الإلكترونية.</option>
            <option value="التوقيــع عــن ولــي الأمر مــن غيــر علمــه علــى المكاتبــات المتبادلــة بيــن المدرســة وولــي الأمر .">التوقيــع عــن ولــي الأمر مــن غيــر علمــه علــى المكاتبــات المتبادلــة بيــن المدرســة وولــي الأمر .</option>
         </select>
         <select class="form-control" v-if="level == '4' && stage == '1'" v-model="bi">
            <option value="التحرش المباشر">التحرش المباشر</option>
            <option value="إشعال النار داخل المدرسة.">إشعال النار داخل المدرسة.</option>
            <option value="حيــازة الســجائر أو ا لتد خيــن .">حيــازة الســجائر أو ا لتد خيــن .</option>
            <option value=".إحضــار آلــة حــادة للمدرسـة بقصـد التهديـد أو الاعتداء.">.إحضــار آلــة حــادة للمدرسـة بقصـد التهديـد أو الاعتداء.</option>
         </select>
         <select class="form-control" v-if="level == '4' && stage == '2'" v-model="bi">
            <option value="الإصــرار علــى تــرك أداء الصــلاة مــع الطــلاب والطالبــات والمعلميــن دون عــذر شــرعي.">الإصــرار علــى تــرك أداء الصــلاة مــع الطــلاب والطالبــات والمعلميــن دون عــذر شــرعي.</option>
            <option value="العبــث بالمــواد أو الأدوات والألعاب الخطــرة في المدرســة مثــل: المفرقعــات والمــواد الحارقــة.">العبــث بالمــواد أو الأدوات والألعاب الخطــرة في المدرســة مثــل: المفرقعــات والمــواد الحارقــة.</option>
            <option value="تعمــد إصابــة أحــد الطــالب أو احــدى الطالبــات عــن طريــق الضــرب باليــد أو اســتخدام أدوات غيـر حـادة تحـدث إصابـة">تعمــد إصابــة أحــد الطــالب أو احــدى الطالبــات عــن طريــق الضــرب باليــد أو اســتخدام أدوات غيـر حـادة تحـدث إصابـة</option>
            <option value="التدخين داخل المدرسة.">التدخين داخل المدرسة.</option>
            <option value="الهروب من المدرسة.">الهروب من المدرسة.</option>
            <option value="التنمر">التنمر</option>
            <option value="-عــرض أو توزيــع المــواد الإعلامية الممنوعــة المقــروءة أو المســموعة أو المرئيــة.">-عــرض أو توزيــع المــواد الإعلامية الممنوعــة المقــروءة أو المســموعة أو المرئيــة.</option>
            <option value="شــبهة تزويــر الوثائــق أو تقليــد الأختام الرســمية.">شــبهة تزويــر الوثائــق أو تقليــد الأختام الرســمية.</option>
            <option value="العبــث بتجهيــزات المدرســة أو مبانيهــا، مثــل: أجهــزة الحاســوب ، المعامــل ، حافلــة المدرســة ، الأدوات الكهربائيـة ومعـدات الأمن والسـامة .">العبــث بتجهيــزات المدرســة أو مبانيهــا، مثــل: أجهــزة الحاســوب ، المعامــل ، حافلــة المدرســة ، الأدوات الكهربائيـة ومعـدات الأمن والسـامة .</option>
            <option value="إحضـار أجهـزة الاتصال الشـخصية ( أيـاً كان نوعهـا إلـى المدرسـة ) تحتـوي علـى صـور أو مقاطـع غيـر لائقة">إحضـار أجهـزة الاتصال الشـخصية ( أيـاً كان نوعهـا إلـى المدرسـة ) تحتـوي علـى صـور أو مقاطـع غيـر لائقة</option>
            <option value="تصويــر الطــالب أو التســجيل الصوتــي لهــم بالأجهزة الإلكترونية ( خــاص بالبنيــن )">تصويــر الطــالب أو التســجيل الصوتــي لهــم بالأجهزة الإلكترونية ( خــاص بالبنيــن )</option>
            <option value="امتهان الكتب الدراسية .">امتهان الكتب الدراسية .</option>
         </select>
         <select class="form-control" v-if="level == '5' && stage == '2'" v-model="bi">
            <option value="الاستهزاء بشــيء مــن شــعائر الإسلام واعتنــاق الأفكار أو المعتقــدات المضللــة أو ممارســة طقــوس دينيــة محرمــة.">الاستهزاء بشــيء مــن شــعائر الإسلام واعتنــاق الأفكار أو المعتقــدات المضللــة أو ممارســة طقــوس دينيــة محرمــة.</option>
            <option value="الإساءة للدولة أو رموزها">الإساءة للدولة أو رموزها</option>
            <option value="تعمــد إتلاف أو تخريــب شــيء مــن تجهيـزات المدرسـة أو ممتلكاتهـا أومرافقهـا، مثـل: الأدوات الكهربائيـة، أجهــزة الحاســوب، الآت التشــغيل والمعامــل ، وحافلــة المدرســة .">تعمــد إتلاف أو تخريــب شــيء مــن تجهيـزات المدرسـة أو ممتلكاتهـا أومرافقهـا، مثـل: الأدوات الكهربائيـة، أجهــزة الحاســوب، الآت التشــغيل والمعامــل ، وحافلــة المدرســة .</option>
            <option value=" الاسـتخدام والاستفادة مــن الوثائــق، أو الأختام المــزورة أو الرســمية بطريقــة غيــر مشــروعة نظامــاً"> الاسـتخدام والاستفادة مــن الوثائــق، أو الأختام المــزورة أو الرســمية بطريقــة غيــر مشــروعة نظامــاً</option>
            <option value="التحرش">التحرش</option>
            <option value=".تصوير الطالبات أو التسجيل الصوتي لهن بالأجهزة الإلكترونية ( خاص بالبنات)">.تصوير الطالبات أو التسجيل الصوتي لهن بالأجهزة الإلكترونية ( خاص بالبنات)</option>
            <option value=".إشعال النار داخل المدرسة.">.إشعال النار داخل المدرسة.</option>
            <option value=" حيازة أو استخدام أو تهديد الطلاب والطالبات بالأسلحة النارية أو ما في حكمها مثل السكاكين والأدوات الحادة والرصاص بدون مسدس."> حيازة أو استخدام أو تهديد الطلاب والطالبات بالأسلحة النارية أو ما في حكمها مثل السكاكين والأدوات الحادة والرصاص بدون مسدس.</option>
            <option value=".الجرائم المعلوماتية.">.الجرائم المعلوماتية.</option>
            <option value=".حيازة، أو تعاطي، أو ترويج المخدرات أو المسكرات.">.حيازة، أو تعاطي، أو ترويج المخدرات أو المسكرات.</option>
         </select>
       </div>
       <div class="form-group">
         <label for="">الاجراء المتخذ</label>
         <div class="col-12 g" style="border: solid 2px; overflow-y: auto; height: 135px">
            <div class="form-check"  v-for="actiona in actions" :key="actiona">
              <label class="form-check-label">
                <input type="checkbox" class="form-check-input" v-model="action" :value="actiona">
                {{ actiona }}
              </label>
            </div>
         </div>
       </div>
       <div class="row">
        <div class="col-12 col-lg-4 g">
            <button class="btn btn-success btn-block" @click="send=1;done()">
                <i class="fa fa-paper-plane"></i>
                ارسال لولي الامر فقط
            </button>
        </div>
        <div class="col-12 col-lg-4 g">
            <button class="btn btn-primary btn-block" @click="send=2;done()">
                <i class="fa fa-save"></i>
                حفظ في ملف الطالب
            </button>
        </div>
        <div class="col-12 col-lg-4 g">
            <button class="btn btn-danger btn-block" @click="send=3;done()">
                <i class="fa fa-save"></i>
                <i class="fa fa-paper-plane"></i>
                ارسال وحفظ
            </button>
        </div>
       </div>
    </b-modal>
    <b-modal id="send" title="رسالة ولي الامر" hide-footer size="sm">
        <div class="form-group">
          <label for="">رسالة ولي الامر</label>
          <textarea class="form-control" v-model="message" rows="3"></textarea>
        </div>
        <div class="form-group">
          <label for="">ارسال عبر</label>
          <select class="form-control" v-model="sendmethod">
            <option value="whatsapp">الواتس الذكي</option>
            <option value="sms">الرسائل النصية SMS</option>
          </select>
        </div>
        <div class="alert text-center border g">
            <span v-if="sendmethod == 'whatsapp'">
                يمكنك التأكد من ربط الواتس اب <a href="/_whatsapp" target="_blank">من هنا</a>
            </span>
            <span v-if="sendmethod == 'sms'">
                يمكنك التأكد من اعدادات الـSMS <a href="/sms" target="_blank">من هنا</a>
            </span>
        </div>
        <div class="col-12 text-center g">
            <button class="btn btn-primary" @click="method=true;done()">
                ارسال الآن
            </button>
            <br><br>
            *تأكد من اعدادات الارسال من صفحة الاعدادات
        </div>
        <hr>
    <div class="col-12 text-center">
        - او -
    </div>
        <hr>
        <button class="btn btn-block btn-success" @click="sendmethod='wa';wa=true;done()">
            <i class="fa fa-whatsapp"></i>
            ارسال عبر اداة المتصفح
        </button>
    </b-modal>
    <button class="dnone" id="sssend" v-b-modal.send></button>
  </div>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { VBModal, BModal, BFormInput } from "bootstrap-vue";
export default {
  components: {
    // Layouts
    BModal,BFormInput},
  directives: {
    Ripple,
    "b-modal": VBModal,
  },
    data(){
        return {
            date: new Date().toISOString().split("T")[0],
            bis: [],
            students: [],
            current: {},
            createforward: {},
            wa: false,
            saved: false,
            info: {},
            selected: [],
            q: null,
            user: JSON.parse(localStorage.getItem('user')),
            level: 1,
            stage: 1,
            bi: "",
            action: [],
            manual_bi: false,
            classrooms: [],
            selectedclassroom: 'all',
            send: null,
            method: null,
            sendmethod: "whatsapp",
            select_mode: false,
            selected_to_print: [],
            message: `تم تسجيل مخالفة سلوكية على ابنكم {name}\nالمخالفة: {bi}\الاجراء المتخذ: {action}`,
            actions: [
                "إشعار ولي الأمر بالمخالفة والإجراءات المتخذه",
                "أخذ تعهد خطي على الطالب",
                "إعتذار الطالب عن ما بدر منه",
                "إصلاح ما أتلفه الطالب",
                "مصادرة المواد الممنوعة التي أحضرها",
                "نقل الطالب المصاب للمركز الصحي",
                "حسم درجة من درجات السلوك",
                "حسم درجتان من درجات السلوك",
                "حسم ثلاث درجات من درجات السلوك",
                "حسم عشر درجات من درجات السلوك",
                "حسم خمس عشر درجة من درجات السلوك",
                "الإحالة للموجه الطلابي لدراسة حالته متمكينه من تعويض الدرجات",
                "استدعاء ولي الأمر وتوقيعه بالعلم على المشكلة والإجراءات المتخذة",
                "نقل الطالب إلى فصل آخر",
                "الإحالة إلى لجنة التوجيه الطلابي للمساعدة في علاج وضع الطالب وفقا لتقرير الموجه الطلابي",
                "إنذار بالنقل لمدرسة أخرى في حال تكرار المخالفة",
                "الإحالة  لإدارة / قسـم التوجيـه الطلابــي",
                "الرفع لإدارة التعليم بالنقل لمدرسة أخرى",
                "إشعار ولي الأمر لأخذ رأيه في المدرسة التي سينقل ابنه إليها"
            ],
            studentsObj: {},
            ntype: false
        }
    },
    created(){
        if(!checkPer("students|bis")){
            this.$router.push('/NotPermitted')
            return false;
        }
        var g = this;
        g.getStudents()
        g.getReport()
        $.post(api + '/user/teachers/info', {
            jwt: g.user.jwt
        }).then(function(r){
            g.info = r.response
        })
        if(window.location.hash){
            setTimeout(function(){
                $(".bbbtn").click()
                g.selected = [window.location.hash.replace("#", '')]
            }, 500)
        }
    },
    methods: {
        selectedclassroomvalid(bi){
            var g = this;
            var re = false;
            bi.students.forEach(function(a){
                if(g.selectedclassroom == 'all' || g.selectedclassroom == a.classname + ' - ' + a.classroom){
                    re = true;
                }
            })
            return re;
        },
        forward(){
            var g = this;
            $.post(api + '/user/students/bis/forward', {
                jwt: this.user.jwt,
                type: this.createforward.type,
                opinion: this.createforward.opinion,
                details: this.createforward.details,
                students: this.current.students.map(function(x){return x.number}),
                repeat: this.createforward.repeat,
                admin: this.createforward.admin == 'yes' || this.createforward.admin == true ? 'yes' : 'no'
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response, 200)
                }else{
                    $("#forward___BV_modal_header_ > button").click();
                    alert("تم تحويل بنجاح", 100)
                    g.getReport()
                }
            }).fail(function(){
            })
        },
        ccc(){
            if(this.createforward.type == ""){
                this.ntype = true;
            }
        },
        print(arr){
        var g = this;
        var html = "";
        var win = window.open("");
        arr.forEach(function(bi){
        var index = bi.index, bi = bi.bi;
        html = html + (`
            <div class='container'>
            <div class="row" style='padding-top: 10px'>
                <div class="col-4 text-center">
                <p>
                    المملكة العربية السعودية
                    <br>
                    وزارة التعليم
                    <br>
                    الادارة العامة للتعلم والارشاد بالوزارة
                </p>
                </div>
                <div class="col-4 text-center">
                <img style="width: 150px !important;" src="https://cdn-static.brmja.com/storage/scrapped/62793ceb03c19132886968.jpg">
                </div>
                <div class="col-4 text-center">
                <p style='padding-top: 5px'>
                المرسل: ${g.user.name}
                <br>
                التاريخ: ${new Date().toISOString().split("T")[0]}
                </p>
                </div>
                <div class='col-12 text-center'>
                <h4>تعـــهد</h4>
                </div>
                <div class='col-12'>
                    <div style='border: solid 2px black; padding: 10px'>
                        انا الطالب /ة : ${bi.students[index]?.name} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        بـ ${bi.students[index]?.classname}
                        <br>
                        <p>
                            اقر بأنني ارتكبت في تاريخ: ${bi.date.split(" ")[0]} مخلفة سلوكية<br>
                            وهي: ${bi.bi}
                            <br>
                            <br>
                            واتعهد بعدم تكرار أي مخالفة سلوكية مستقبلاً.
                            <br>
                            وعلى ذلك جرى التوقيع
                            <br>
                            <div class='row'>
                                <div class='col-6'><h5>
                            الطالب/ة </h5>
                                    اسم الطالب/ة : .....................................................................
                                        <br>
                                        التوقيع      : ............................................................................
                                        <br>
                                        التاريخ      : ............................................................................
                                </div>
                                <div class='col-6'><h5>
                            وكيل المدرسة: </h5>
                                    الاسم : .....................................................................
                                        <br>
                                        التوقيع      : .....................................................................
                                        <br>
                                        التاريخ      : .....................................................................
                                </div>
                            </div>
                        </p>
                    </div>
                </div>
                <div class='col-12'><br><br>
                    <div style='border: solid 2px black; padding: 10px'>
                        انا ولى أمر الطالب /ة : ${bi.students[index]?.name} &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        بـ ${bi.students[index]?.classname}
                        <br>
                        <p>
                            ابلغت بمخالفة ابني / ابنتي المتمثلة في:<br>
                            وهي: ${bi.bi}
                            <br><br>
                            وسأقوم بالتعاون مع المدرسة في تعديل سلوكه/سلوكها بما يحقق المصلحة العامة
                            <br>
                            وعلى ذلك جرى التوقيع<br><br>
                            <div class='row'>
                                <div class='col-6'><h5>
                ولي الأمر</h5>
                                    الاسم : .....................................................................
                                        <br>
                                        التوقيع      : ............................................................................
                                        <br>
                                        التاريخ      : ............................................................................
                                </div>
                                <div class='col-6'><h5>
                            وكيل المدرسة: </h5>
                                    الاسم : .....................................................................
                                        <br>
                                        التوقيع      : .....................................................................
                                        <br>
                                        التاريخ      : .....................................................................
                                </div>
                            </div>
                        </p>
                    </div>
                </div>
                <div class='col-12 text-center'>
                <br>
               الادارة العامة للتعلم والارشاد بالوزارة ... قواعد السلوك والمواظبة
                </div>
            </div>
            </div>
        `)
            
        })
        win.document.write(`
        <html dir="rtl">
            <head>
            <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap@5.0.2/dist/css/bootstrap.rtl.min.css">
            <style>
                .bg-dark{
                background: #DDDDDD !important
                }
                        .container{
                        page-break-after:always;
                        }
            </style>
            </head>
            <body>
            ${html}
            </body>
        </html>
        `)
        win.document.close()
        setTimeout(() => {
            win.print()
        }, 1000);
        },
        remove(id){
            if(confirm('متأكد من حذف المخالفة؟')){
                var g = this;
                $.post(api + '/user/students/bis/delete', {
                    jwt: this.user.jwt,
                    id: id
                }).then(function(r){
                    g.loading = false
                    if(r.status != 100){
                        alert(r.response)
                    }else{
                        g.getReport()
                    }
                }).fail(function(){
                })
            }
        },
        getReport(){
            var g = this;
            $.post(api + '/user/students/bis/all', {
                jwt: this.user.jwt
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.bis = r.response
                    g.bis.forEach(function(a){
                        a.students.forEach(function(b){
                            if(!g.classrooms.includes(b.classname + ' - ' + b.classroom)){
                                g.classrooms.push(b.classname + ' - ' + b.classroom)
                            }
                        })
                    })
                }
            }).fail(function(){
            })
        },
        getStudents(){
            var g = this;
            $.post(api + '/user/students/list', {
                jwt: this.user.jwt,
                q: this.q,
                page: 1,
                perpage: 10000
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.students = r.response
                    if(window.location.hash && !g.q){
                        g.students.forEach(function(a){
                            if(a.number == window.location.hash.replace("#", "")){
                                g.q = a.name;
                                g.getStudents()
                            }
                        })
                    }
                }
            }).fail(function(){
                g.loading = false;
            })
        },
        done(){
            var g = this;
            if(!g.bi || g.bi == ""){
                alert("يجب اختيار المخالفة")
                return;
            }
            if(g.selected.length == 0){
                alert("برجاء اختيار الطلاب")
                return;
            }
            if(!g.method && g.send != 2 && !g.wa){
                $("#sssend").click()
                return;
            }
            if(g.wa){
                var messages = [];
                g.students.forEach(function(a){
                    if(g.selected.includes(a.number)){
                        messages.push({
                            phones: a.phones,
                            message: g.message.replace("{name}", a?.name).replace("{action}", g.action?.join(", ")).replace("{bi}", g.bi?.toString())
                        })
                    }
                })
                $("#sendmodalwaarray").val(JSON.stringify(messages))
                $("#sendmodalwatype").val("students")
                $("#sendWAModal").click()
            }
            if(g.saved){
                return;
            }
            $.post(api + '/user/students/bis/add', {
                jwt: this.user.jwt,
                send: this.send,
                actions: this.action,
                method: this.sendmethod,
                bi: this.bi,
                students: this.selected,
                message: this.message
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    alert(r.response)
                }else{
                    g.saved = true;
                    if(!g.wa){
                        location.reload()
                    }
                }
            }).fail(function(){
                g.loading = false;
            })
        }
    }
}
</script>

<style>

</style>